import React from "react"
import styled from "@emotion/styled"
import theme from "../../styles/theme"

const HeroUnit = ({ className }) => {
  return (
    <section className={className}>
      <StyledWrapper className="inner center">
        <h3 className="text-center">
          informer <br />
          <em>recompenser</em>
          <br />
          <em className="variant">transformer</em>
        </h3>
        <StyledDesktopIframeWrapper>
          <iframe
            width="100%"
            height="650"
            src="https://www.youtube.com/embed/5_Wy1uoq2-I"
            title="WeRECY : Recycle , les commerçants te recompenses"
            frameborder="0"
            allow="fullscreen"
          ></iframe>
        </StyledDesktopIframeWrapper>
        <StyledDMobileIframeWrapper>
          <iframe
            width="100%"
            height="300"
            src="https://www.youtube.com/embed/5_Wy1uoq2-I"
            title="WeRECY : Recycle , les commerçants te recompenses"
            frameborder="0"
            allow="fullscreen"
          ></iframe>
        </StyledDMobileIframeWrapper>
      </StyledWrapper>
    </section>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledDesktopIframeWrapper = styled.div`
  width: 100%;
  display: none;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    display: block;
  }
`

const StyledDMobileIframeWrapper = styled.div`
  width: 100%;
  display: block;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    display: none;
  }
`

export default HeroUnit
