import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import theme from "../../styles/theme"

const Loop = ({ className }) => {
  return (
    <section className={className}>
      <div className="inner">
        <h3 className="text-center">
          Boucler la <em className="variant">boucle</em>
        </h3>
        <div className="grid grid-section grid_3-2">
          <div>
            <StyledParagraph>
              Prenons l’exemple d’un jean qui a tellement vécu qu’il ne peut
              plus être vendu sur la plateforme Vinted... Collectés puis
              transportés auprès des entreprises spécialisées, ce jean sera
              transformé en brique isolante pour l’habitat. Une cercle vertueux
              pour trois acteurs :
            </StyledParagraph>
            <StyledParagraph>
              Pour son geste, le consommateur bénéficie d’une réduction dans la
              boutique qui collecte.
            </StyledParagraph>
            <StyledParagraph>
              Le commerçant reçoit et fidélise de nouveaux clients. Il
              centralise la collecte.
            </StyledParagraph>
            <StyledParagraph>
              Enfin, la matière collectée est distribuée aux producteurs
              écoresponsables pour être transformée et valorisée.
            </StyledParagraph>
            <p>
              Et les exemples ne manquent pas ! La combinaison de surf renaît en
              tapis de yoga, des pots de yaourts en meubles design, les
              batteries de vélo en stockage d’énergie pour l’habitat.
            </p>
          </div>
          <StyledImageWrapper>
            <DesktopImageWrapper>
              <StaticImage
                imgStyle={{
                  borderRadius: theme.shape.borderRadius[0],
                  boxShadow: theme.elevation.level2,
                }}
                height={450}
                width={250}
                css={css`
                  border-radius: ${theme.shape.borderRadius[0]};
                  box-shadow: ${theme.elevation.level2};
                `}
                placeholder="blurred"
                src="../../images/challenge-loop.jpg"
                alt=""
              />
            </DesktopImageWrapper>
            <MobileImageWrapper>
              <StaticImage
                imgStyle={{
                  borderRadius: theme.shape.borderRadius[0],
                  boxShadow: theme.elevation.level2,
                }}
                css={css`
                  border-radius: ${theme.shape.borderRadius[0]};
                  box-shadow: ${theme.elevation.level2};
                `}
                placeholder="blurred"
                src="../../images/challenge-loop.jpg"
                alt=""
              />
            </MobileImageWrapper>
          </StyledImageWrapper>
        </div>
      </div>
    </section>
  )
}

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledParagraph = styled.p`
  margin-bottom: 1rem;
`

const DesktopImageWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    display: block;
  }
`
const MobileImageWrapper = styled.div`
  margin-top: 2rem;
  display: block;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    display: none;
  }
`

export default Loop
