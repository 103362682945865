import React from "react"
import styled from "@emotion/styled"

import HeroUnit from "../components/challenge-page/hero-unit"
import Loop from "../components/challenge-page/loop"
import Pillars from "../components/challenge-page/pillars"
import Video from "../components/challenge-page/video"
import Layout from "../components/layout"
import theme from "../styles/theme"

const Challenge = () => {
  return (
    <Layout>
      <StyledVideo className="section-full" />
      <HeroUnit />
      <Pillars />
      <Loop />
    </Layout>
  )
}

const StyledVideo = styled(Video)`
  padding-top: calc(2em + ${theme.layout.headerHeight});
`

export default Challenge
