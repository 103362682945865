import React from "react"
import styled from "@emotion/styled"

const HeroUnit = ({ className }) => {
  return (
    <section className={className}>
      <StyledWrapper className="inner center">
        <StyledParagraph>
          Nous simplifions la connexion entre tous les acteurs d'une boucle
          vertueuse. Ensemble, transformons la matière dormante en matière
          première pour éviter qu'elle soit enfouie ou incinérée. En plus on te
          recompense.
        </StyledParagraph>
        <StyledParagraph>
          Nous œuvrons pour un monde qui a du sens où on ne brûle plus les
          déchêts par simplicité. Nous redistribuons aux bonnes personnes les
          déchêts qui ont de la valeur pour créer une nouvelle matière.
        </StyledParagraph>
        <StyledParagraph>
          Des tonnes d'objets high tech, textiles, batteries, cartouches d'encre
          sont jetés au mauvais endroit ou sont tout simplement oubliés. Leur
          production et notre consommation ne cessent d'augmenter parce ce que
          nous ne sommes pas prêts, dans l'immédiat, à renoncer au confort que
          nous apporte la consommation de ces objets. Demain, peut-être, nous
          consommerons moins et mieux pour la sauvegarde de la planète.
          Aujourd'hui, nous souhaitons assumer le traitement de nos objets et
          déchets, fruits de plusieurs décennies de consommation de masse.
        </StyledParagraph>
        <StyledParagraph>
          Toute cette matière produite est un formidable réservoir de ressources
          que l'on peut réutiliser à bon escient. C'est le moteur extraordinaire
          de l'économie circulaire. WeRECY s'inscrit dans ce processus en y
          apportant son expertise en matière de collecte, recyclage,
          redistribution.
        </StyledParagraph>
        <p>
          Nous sommes convaincus qu'il faut combler le fossé entre écologie et
          économie pour sauvegarder la planète. Et pour ça WeRECY repose sur
          deux piliers complémentaires...
        </p>
      </StyledWrapper>
    </section>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledParagraph = styled.p`
  margin-bottom: 1rem;
`

export default HeroUnit
