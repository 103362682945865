import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

const Pillars = ({ className }) => {
  return (
    <section className={className}>
      <div className="inner">
        <h3 className="text-center">
          Partir sur de <em className="varient">bonnes bases</em>
        </h3>
        <div className="grid grid-section grid_2-3">
          {/* @todo : remove useless divs */}
          <StyledImageWrapper>
            <StyledImageInnerWrapper>
              <StaticImage
                placeholder="blurred"
                src="../../images/business_logistics_box.png"
                alt=""
              />
            </StyledImageInnerWrapper>
          </StyledImageWrapper>
          <div>
            <h4 className="dark">Incitation</h4>
            <p className="margin-bottom">
              Je peux participer à l’aventure en déposant mon objet auprès d’une
              boutique autour de chez moi. Je reçois une récompense pour mon
              engagement.
            </p>
            <h4 className="dark">Information</h4>
            <p>
              Je sais concrètement comment mon objet sera transformé et
              valorisé. C’est une motivation supplémentaire pour l’apporter à la
              boutique et lui donner une seconde vie.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledImageInnerWrapper = styled.div`
  max-width: 35vh;
  display: flex;
  justify-content: center;
`

export default Pillars
